import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';

import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import {
  AssetLoaderService,
  AuthService,
  Environment,
  IconClientService,
} from '@s2a/core';
import {
  AssetRegistry,
  FlexLayoutHackModule,
  IconRegistry,
  TranslationModule,
} from '@s2a-core/ng-core';
import { NgShellModule } from '@s2a/ng-shell';

import { MaterialModule } from './app.material.module';
import { AppComponent } from './app.component';
import { CoverImageComponent } from './components/cover-image/cover-image.component';
import { FooterComponent } from './components/footer/footer.component';
import { environment } from '../environments/environment';
import { b2cConfig } from './config/b2cConfig';
import { S2AClientApplication } from './client/s2a-msal-client.application';

@NgModule({
  declarations: [AppComponent, CoverImageComponent, FooterComponent],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    NgShellModule.forRoot(),
    TranslationModule.forRoot(environment),
    FlexLayoutHackModule,
    MsalModule,
  ],
  providers: [
    {
      provide: Environment,
      useValue: environment,
    },
    IconClientService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: IconRegistry,
      deps: [IconClientService, MatIconRegistry, DomSanitizer, Environment],
    },
    { provide: AuthService, useValue: new AuthService(environment)},
    AssetLoaderService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: AssetRegistry,
      deps: [AssetLoaderService, Environment],
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: () => new S2AClientApplication(b2cConfig)
    },
    MsalService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
