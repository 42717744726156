import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';
import { Logger } from '@s2a-core/ui-logger';

import { environment } from '../../environments/environment';

const logger = Logger('B2CConfiguration');
const REDIRECT_ROUTE = 'account-selection';
const KRONES_WORLD = 'krones.world';
const USER_FLOW_PREFIX = 'b2c_1a_cust_susi_signup_signin';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const constructAuthorityUrl = (): string => {
  const tenantPrefix = `${environment.b2cConfiguration.tenantDomainPrefix}`;
  const userFlow = `${USER_FLOW_PREFIX}_${environment.b2cConfiguration.userFlow}`;
  const envDomain = environment.b2cConfiguration.envDomain;
  const authorityUrl = `https://login${envDomain}.${KRONES_WORLD}/${tenantPrefix}.onmicrosoft.com/${userFlow}`;
  return authorityUrl;
};

export const b2cConfig: Configuration = {
  auth: {
    authority: constructAuthorityUrl(),
    clientId: environment.b2cConfiguration.clientId,
    knownAuthorities: [`https://login${environment.b2cConfiguration.envDomain}.${KRONES_WORLD}/`],
    redirectUri: `${environment.b2cConfiguration.kwUrl}${REDIRECT_ROUTE}`
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (_logLevel: LogLevel, message: string) => { logger.info(message); },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
};
