import { v4 as uuidv4 } from 'uuid';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AuthService, LanguageKey, Translation } from '@s2a/core';
import { showLanguageDialog, SessionService } from '@s2a/ng-shell';

import { RouterService } from './services/router.service';
import { S2AClientApplication } from './client/s2a-msal-client.application';

@UntilDestroy()
@Component({
  selector: 's2a-login-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('languageDialog', { static: true }) languageDialog: TemplateRef<any>;
  @ViewChild('login', {static: true}) loginWC: ElementRef<any>;

  currentLanguageKey: string;

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private translateService: Translation,
    private sessionService: SessionService,
    private routerService: RouterService,
    private azureAuthService: MsalService,
  ) {
    this.currentLanguageKey = this.translateService.getCurrentLanguage();
  }

  ngOnInit() {
    this.loginWC.nativeElement.addEventListener('login-event', this.onS2aLogin.bind(this));
    this.loginWC.nativeElement.addEventListener('loginViaKW', this.login.bind(this));
  }

  changeLanguage(): void {
    showLanguageDialog(this.dialog, this.languageDialog)
      .pipe(untilDestroyed(this))
      .subscribe((language: LanguageKey) => {
        this.currentLanguageKey = language;
        this.translateService.switchLanguage(language).subscribe();
      });
  }

  /**
   * The function is called when user click on login button to login into share 2 act environment
   *
   */
  onS2aLogin(event: CustomEvent): void {
    const sessionId = uuidv4();
    event.detail.user.sessionId = sessionId;
    this.sessionService.setSession(event.detail.user);
    this.authService.isLoggedIn = true;
    this.routerService.redirectToApp();
  }

  login() {
    // This method is required as MSAL create few keys in session storage in each redirect call.
    // This keeps on increasing as we click on button to login via kw.
    // MSAL library by default removes those added keys by themselves but that hold true only when we redirect to
    // the same application after successful login.
    // This holds void in our case as we are redirecting to the KW application after successful login
    (this.azureAuthService.instance as S2AClientApplication).cleanRequestByInteractionType(InteractionType.Redirect);
    this.azureAuthService.loginRedirect();
  }

  ngOnDestroy(): void {
    this.loginWC.nativeElement.removeEventListener('login-event', this.onS2aLogin);
    this.loginWC.nativeElement.removeEventListener('loginViaKW', this.login);
  }
}
