import { Component, ViewEncapsulation } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 's2a-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class FooterComponent {
  imageUrl: string;

  constructor() {
    this.imageUrl = environment.imageUrl;
  }

  get isChinaRegion(): boolean {
    let baseDomain = location.hostname.split('.').slice(1).join('.');
    baseDomain = baseDomain === 'localhost' ? 'share2act-dev.io' : baseDomain;
    return baseDomain.endsWith('.cn');
  }

}
