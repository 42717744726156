<div class="other-info">
  <div class="policies" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="space-between start" fxLayoutGap="1rem" fxLayoutGap.gt-xs="0rem">
    <!-- Legal -->
    <div  *ngIf="!isChinaRegion; else chinaContent" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="0.4rem">
      <div fxLayout="row" fxLayoutGap="0.4rem">
        <a class="brand-icon" target="_blank" href="https://www.syskron.com/imprint">Imprint</a>
        <span>|</span>
      </div>
      <a class="brand-icon" target="_blank" href="https://www.syskron.com/privacy-policy">Privacy
        Policy</a>
    </div>
    <!-- Brand Link/Logo -->
    <div>
      <a target="_blank" href="https://digital-services.krones.com/">
        <mat-icon class="krones-logo" svgIcon="kronesWorldLogo_light"></mat-icon>
      </a>
    </div>
  </div>

</div>
<ng-template #chinaContent>
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="0.4rem">
    <div fxLayout="row" fxLayoutGap="0.4rem">
      <a class="brand-icon" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32058502010628">
        <img class="icp-icon" src="{{imageUrl}}img/icp_cn_gov.png" />
        苏公网安备 32058502010628号</a>
      <span>|</span>
    </div>
    <a class="brand-icon" target="_blank" href="https://beian.miit.gov.cn/">
      苏ICP备18049390号-1</a>
  </div>
</ng-template>
