<div class="wrapper">
  <div class="page-container">
    <s2a-cover-image class="cover-image-container"></s2a-cover-image>
    <div class="side-container">
      <!-- Change language button -->
      <div fxFlex="nogrow" fxFlex.gt-sm="1 0 auto">
        <div class="change-language" fxLayout="row" fxLayoutAlign="end">
          <button
            class="language-button"
            mat-button
            color="accent"
            (click)="changeLanguage()"
          >
            <mat-icon>translate</mat-icon>
            <span
              translate
              class="text-wrapper"
              fxHide="true"
              fxShow.gt-xs="true"
            >
              components.login.change_language
            </span>
          </button>
        </div>
      </div>
      <div fxFlex="1 0 auto" fxLayout="column">
        <s2a-login #login [language]="currentLanguageKey"></s2a-login>
      </div>
      <s2a-footer></s2a-footer>
    </div>
  </div>
</div>
<ng-template #languageDialog>
  <s2a-language-selector
    [selectedLanguageKey]="currentLanguageKey"
  ></s2a-language-selector>
</ng-template>
