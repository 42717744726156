import { Configuration, InteractionType, PublicClientApplication } from '@azure/msal-browser';

/**
 * This is the extended version of Client Application that is provided by default by the msal library for Angular.
 * The reason of extending is because of our requirement where we need to clear all the session storage items
 * when user click on the login with kw button.
 *
 * Ideally, it is the responsibility of this library to remove those keys but that holds correct only when we
 * redirects to the same application, but in our case we are redirecting to the different application i.e; `Krones World`
 */
export class S2AClientApplication extends PublicClientApplication {

  constructor(config: Configuration) {
    super(config);
  }

  /**
   * Looks in temporary cache for any state values with the provided interactionType and removes all temporary cache items for that state
   * Used in scenarios where temp cache needs to be cleaned but state is not known, such as clicking browser back button.
   *
   */
  public cleanRequestByInteractionType(interactionType: InteractionType): void {
    this.browserStorage.cleanRequestByInteractionType(interactionType);
  }
}
